/* :root {
  --white: #ffffff;
  --first-color: #d8e56b;
  --primary-color: #1e2341;
  --third-color: #ffffff;
  --fourth-color: #dddddd;
  --text-color: #282828;
  --btn-color: #ff5757;
  --dark-border: #313131;
  --dark-text: #6d6d6d;
  --dark-headings: #afafaf;
  --dark-bg: #101010;
} */

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  /* width: 100%;
  min-height: 100%;
  height: 100vh;*/
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: U8Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  background-color: #ffffff; */
}
*::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
}
a,
a:hover {
  text-decoration: none !important;
  text-decoration-color: white;
}
p {
  margin-top: 0;
  margin-bottom: 0 !important;
}
@font-face {
  font-family: U8Black;
  src: url("./assets/fonts/U8-Black.otf");
}
@font-face {
  font-family: U8Regular;
  src: url("./assets/fonts/U8-Regular.otf");
}
